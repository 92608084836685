import { WidgetComponent } from "forms/components/widget";
import classes from "./DateInput.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import { ru } from "date-fns/locale";
import "./Style.css";
import Birthday from "components/ui/icons/Birthday";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { newDateFormat } from "helpers/dates";
import { useEffect, useState } from "react";

const DateInput = ({
  label,
  name,
  placeholder,
  value,
  inputArgs,
  helpText,
  readOnly,
  disabled,
  onChange,
  fieldClass,
  required,
  widgetArgs,
  resDataAfterSubmit,
  form,
  autoFocus,
  onFocus,
  onBlur,
  theme = "shadow",
}: WidgetComponent) => {
  const elId = `id_${name}`;

  registerLocale("ru", ru);

  const [startDate, setStartDate] = useState<Date>();
  const [defaultDate, setDefaultDate] = useState<string>();

  //   const [dataAfterSubmit, setDataAfterSubmit] = useState<string | null>(
  //     null
  //   );
  const [errorAfterSubmit, setErrorAfterSubmit] = useState<string[] | null>(
    null,
  );

  useEffect(() => {
    if (value && typeof value === "string") {
      setDefaultDate(
        value.includes("-")
          ? new Intl.DateTimeFormat("ru", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            }).format(new Date(value))
          : value,
      );
      setStartDate(newDateFormat(value));
    } else {
      setDefaultDate(undefined);
      setStartDate(undefined);
    }
  }, [value]);

  useEffectOnlyOnUpdate(() => {
    if (resDataAfterSubmit) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data, errorMessage } = resDataAfterSubmit;
      if (Object.entries(errorMessage).length !== 0) {
        if (errorMessage[name]) setErrorAfterSubmit(errorMessage[name]);
      }
    }
  }, [resDataAfterSubmit]);

  useEffect(() => {
    if (startDate && +startDate !== +new Date(value as string)) {
      if (errorAfterSubmit) {
        setErrorAfterSubmit(null);
      }

      onChange(startDate);
      setDefaultDate(undefined);
    }
  }, [startDate]);

  let isInline = widgetArgs?.is_inline;

  if (name === "reportStartDateOpen" || name === "reportFinishDateOpen") {
    isInline = true;
  }

  const labelAndValue = form === "projectEditForm";

  let placeholderText;
  if (!placeholder) {
    placeholderText = new Date().toLocaleDateString();
  } else {
    placeholderText = placeholder;
  }

  return (
    <section
      data-error={errorAfterSubmit ? true : undefined}
      className={`
        ${
          fieldClass
            ? [classes.container, fieldClass].join(" ")
            : classes.container
        } ${"react-datepicker-container-" + theme}  ${
        classes["container-" + theme || ""]
      }`}
    >
      {labelAndValue ? (
        <label>
          <span>{label}</span>
          <span>{value?.toString()}</span>
        </label>
      ) : (
        label && (
          <label htmlFor={elId}>
            {placeholder === "Изменить дату" && (
              <Birthday clazz={classes.icon} />
            )}
            {label}
          </label>
        )
      )}
      <DatePicker
        className="planning"
        calendarClassName="planning"
        locale="ru"
        dateFormat="dd.MM.yyyy"
        placeholderText={placeholderText}
        selected={startDate}
        value={defaultDate}
        onChange={(date: Date) => {
          setStartDate(date);
        }}
        required={required}
        autoFocus={autoFocus}
        inline={isInline}
        disabled={disabled}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {errorAfterSubmit?.map((i, idx) => {
        return (
          <ErrorComponent key={idx} clazz={classes.error}>
            {`*${i}`}
          </ErrorComponent>
        );
      })}
    </section>
  );
};

export default DateInput;
