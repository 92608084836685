import { useRef, useState, memo, useEffect } from "react";
import DeleteIcon from "../icons/DeleteIcon";
import DoubleLine from "../icons/DoubleIcon";
import PenIcon from "../icons/PenIcon";
import classes from "./FieldEditItemInfo.module.scss";
import SuccessIcon from "../icons/SuccessIcon";
import LinkIcone from "../icons/LinkIcon";

const FieldEditItemInfo = ({
  title,
  onDelete,
  onEditSave,
  onEdit,
  link,
  onClick,
  active,
  offEdit = false,
  offDelete = false,
  readonlyName,
}: {
  title: string;
  onEdit?: () => void;
  onDelete: () => void;
  onEditSave?: (title: string, link: string) => void;
  link?: string;
  onClick?: () => void;
  active?: boolean;
  offEdit?: boolean;
  offDelete?: boolean;
  readonlyName?: boolean;
}) => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(link || "");
  const [valueTitle, setValueTitle] = useState(title || "");

  const handlerEdit = (e: any) => {
    e.stopPropagation();
    setOpen(true);
    onEdit && onEdit();
  };
  const handlerDelete = (e: any) => {
    e.stopPropagation();
    onDelete();
  };

  useEffect(() => {
    setValueTitle(title);
    setValue(link || "");
  }, [open]);

  return (
    <div className={`${classes.item} ${active && !open ? classes.active : ""}`}>
      <div
        className={`${classes.header}`}
        onClick={!open ? onClick : undefined}
      >
        <div className={classes.titleWrap}>
          {open && <DoubleLine />}
          {open ? (
            <input
              value={valueTitle}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setValueTitle(e.target.value);
              }}
              name="title"
              readOnly={readonlyName}
            />
          ) : (
            <p className={classes.title}>{title}</p>
          )}

          {open && onEditSave && (
            <button
              type="button"
              className={classes.btnEditSave}
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
                onEditSave(valueTitle, value);
              }}
            >
              <SuccessIcon />
            </button>
          )}
        </div>
        {!open && (
          <div className={classes.actions}>
            {!offEdit && onEditSave && (
              <button
                type="button"
                className={classes.btnEdit}
                onClick={handlerEdit}
              >
                <PenIcon />
              </button>
            )}
            {!offDelete && (
              <button
                type="button"
                className={classes.btnDelete}
                onClick={handlerDelete}
              >
                <DeleteIcon />
              </button>
            )}
          </div>
        )}
      </div>
      <div className={classes.body} ref={listRef} aria-hidden={!open}>
        <div>
          <div className={classes.link}>
            <LinkIcone />
            <input
              value={value || ""}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setValue(e.target.value);
              }}
              name="link"
              placeholder="Ссылка"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FieldEditItemInfo);
