import classes from "./ManagementSkills.module.scss";
import { useEffect, useMemo, useState } from "react";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import Search from "components/common/search/Search";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce/useDebounce";
import { createParams, getSearchParam } from "helpers/createParams";
import Button from "components/ui/button/Button";
import Plus from "components/ui/icons/Plus";
import ManagementList from "./ManagementList/ManagementList";
import Paper from "components/ui/paper/Paper";
import { SELECT_OPTIONS } from "forms/components/simpleWidgets/select-input/SelectIntputStyles";
import CloseModal from "components/ui/icons/CloseModal";
import Modal from "components/common/modal/Modal";
import { ManegementForm, QualificationForm } from "../ManegmentForms";
import { QualificationFormUserExpertiseContextProvider } from "../ManegmentForms/QualificationForm/context/QualificationFormUserExpertiseLogic";
import {
  QualificationFormUserLevelContextProvider,
  useQualificationFormUserLevelLogic,
} from "../ManegmentForms/QualificationForm/context/QualificationFormUserLevelLogic";

const ManagementSkills = () => {
  const { grades } = useQualificationFormUserLevelLogic();
  const [resetSelect, setResetSelect] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalOpenQualification, setModalOpenQualification] =
    useState<boolean>(false);
  const onHideModal = () => setModalOpen(false);
  const onHideModalQualification = () => setModalOpenQualification(false);

  const gradesСhoices = useMemo(() => {
    const options = [{ name: "Все", label: "all", value: "all" }];
    grades.forEach((item) => {
      options.push({
        name: item.name,
        label: item.id.toString(),
        value: item.id.toString(),
      });
    });
    console.log(options);
    return options;
  }, [grades]);

  const _search = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearchParams(
        createParams(searchParams, { search: e.target.value.trim() }),
      );
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  };
  const search = useDebounce(_search, 500);

  useEffect(() => {
    setResetSelect(true);
  }, [searchParams]);

  const onChangeDepartments = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { department: value.toString() }),
      );
    }
  };

  const onChangePositions = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { position: value.toString() }),
      );
    }
  };

  const onChangeOffices = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(createParams(searchParams, { office: value.toString() }));
    }
  };

  const onChangeGrade = (
    value?: string | number | string[] | Date,
    name?: string | undefined,
  ) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, {
          grade: JSON.stringify(value),
        }),
      );
    }
  };

  useEffect(() => {
    if (resetSelect) setResetSelect(false);
  });

  return (
    <div className={classes.container}>
      <Paper cls={{ paddingRoot: classes.paperPadding }}>
        <div className={classes.header}>
          <div className={classes.selects}>
            <Search
              onInput={search}
              placeholder="Поиск"
              clazz={classes.search}
              initialValue={searchParams.get("search") || ""}
              autoFocus={searchParams.get("search") ? true : false}
              shadow={false}
              clxs={{ clxsContainer: classes.searchWrap }}
            />
            <SelectWrapper
              reset={resetSelect}
              name="positions"
              placeholder="Должность"
              value={getSearchParam(searchParams, "position", false)}
              choices={[
                { name: "Все должности", label: "all", value: "all" },
                {
                  name: "Топ менеджер",
                  label: "top_manager",
                  value: "top_manager",
                },
                {
                  name: "Операционный менеджер",
                  label: "op_manager",
                  value: "op_manager",
                },
                {
                  name: "Проектный менеджер",
                  label: "project_manager",
                  value: "project_manager",
                },
                { name: "Разработчик", label: "developer", value: "developer" },
              ]}
              onChange={onChangePositions}
              searchable={true}
              optionStyle={SELECT_OPTIONS}
              theme="outline"
              isClearable={true}
              className={classes["select-wrapper"]}
            />
            {grades.length > 0 && (
              <SelectWrapper
                reset={resetSelect}
                name="grade"
                placeholder="Уровень"
                value={getSearchParam(searchParams, "grade", true)}
                choices={gradesСhoices}
                onChange={onChangeGrade}
                searchable={true}
                optionStyle={SELECT_OPTIONS}
                theme="outline"
                className={classes["select-wrapper"]}
              />
            )}
            <SelectWrapper
              reset={resetSelect}
              name="departments"
              placeholder="Подразделение"
              value={searchParams.get("department")}
              choices={[
                { name: "Все подразделения", label: "all", value: "all" },
                { name: "Белгород", label: "bel", value: "2" },
              ]}
              onChange={onChangeDepartments}
              searchable={true}
              optionStyle={SELECT_OPTIONS}
              theme="outline"
              className={classes["select-wrapper"]}
            />

            <SelectWrapper
              reset={resetSelect}
              name="offices"
              placeholder="Офис"
              value={searchParams.get("office")}
              choices={[{ name: "Все офисы", label: "all", value: "all" }]}
              onChange={onChangeOffices}
              searchable={true}
              optionStyle={SELECT_OPTIONS}
              theme="outline"
              className={classes["select-wrapper"]}
            />
          </div>
          <div className={classes.buttons}>
            <Button
              type="button"
              clazz={classes.button}
              theme="outline-shadow"
              onClick={() => setModalOpenQualification(true)}
            >
              Настройка квалификации
            </Button>
            <Button
              type="button"
              clazz={classes.button}
              theme="outline-shadow"
              onClick={() => setModalOpen(true)}
            >
              <>
                <Plus clazz={classes.icon} />
                Добавить сотрудника
              </>
            </Button>
          </div>
        </div>
      </Paper>
      <ManagementList />
      <Modal
        hide={onHideModal}
        isShown={modalOpen}
        closeIcon={<CloseModal />}
        clsx={{
          close: classes.modalClose,
          body: classes.modal,
        }}
      >
        <ManegementForm />
      </Modal>
      <Modal
        hide={onHideModalQualification}
        isShown={modalOpenQualification}
        closeIcon={<CloseModal />}
        clsx={{
          close: classes.modalClose,
          body: classes.modal,
        }}
      >
        <QualificationForm />
      </Modal>
    </div>
  );
};

const ManagementSkillsContainer = () => (
  <QualificationFormUserExpertiseContextProvider>
    <QualificationFormUserLevelContextProvider>
      <ManagementSkills />
    </QualificationFormUserLevelContextProvider>
  </QualificationFormUserExpertiseContextProvider>
);

export default ManagementSkillsContainer;
