import { useEffect, useState } from "react";

export const useStateRequest = <T,>({ initData }: { initData: T }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>();
  const [dataRequest, setDataRequest] = useState<T>(initData);

  useEffect(() => {
    if (loading) {
      setError(null);
    }
  }, [loading]);

  const resetStateRequest = () => {
    setLoading(false);
    setError(null);
  };

  return {
    loading,
    setLoading,
    error,
    setError,
    resetStateRequest,
    dataRequest,
    setDataRequest,
  };
};
