import { useCallback, useMemo } from "react";
import classes from "../ManegementForms.module.scss";
import FormColumn, { ISelectedType } from "./FormColumn";
import { IEmplyeePositionGrade, IEmplyeePosition, IEmplyeeGrade } from "types";
import { IGradeAndPositions } from "../QualificationForm/context/QualificationFormUserLevelLogic";

interface IFormUserLevelPorps {
  positions: IEmplyeePosition[];
  grades: IEmplyeeGrade[];
  positionGrades: IEmplyeePositionGrade[];
  activePosition: IEmplyeePosition | null;
  gradesAdvanced: IGradeAndPositions[];
  onClickPosition: React.Dispatch<
    React.SetStateAction<IEmplyeePosition | null>
  >;
  onCreatePosition: (values: {
    name: string;
    responsibilities_link?: string;
  }) => Promise<IEmplyeePosition>;
  onCreatePositionGrade: (
    values: {
      position: number;
      name: string;
      education_link?: string;
    },
    gradeСonnectionID?: number,
  ) => Promise<IEmplyeePositionGrade>;
  onUpdatePosition: (value: {
    title: string;
    link: string;
    data: IEmplyeePosition;
  }) => Promise<IEmplyeePosition>;
  onUpdateGrade: (
    id: number,
    value: {
      name?: string;
      education_link: string;
      possitionGrtadeId: number;
      gradeId: number;
    },
  ) => Promise<IEmplyeePositionGrade>;
  onDeletePosition: (value: IEmplyeePosition) => Promise<boolean>;
  onDeletePositionGrade: (value: IEmplyeePositionGrade) => Promise<boolean>;
  gradesTitleKeys: { [key: number]: string };
  gradesAdvancedPositionActive: IGradeAndPositions[];
  onCreateGrade: (values: {
    name: string;
    level?: number;
  }) => Promise<IEmplyeeGrade>;
}

const FormUserLevel = ({
  positions,
  grades,
  activePosition,
  onClickPosition,
  onCreatePosition,
  onCreatePositionGrade,
  onUpdatePosition,
  onUpdateGrade,
  onDeletePositionGrade,
  onDeletePosition,
  positionGrades,
  gradesAdvanced,
  gradesAdvancedPositionActive,
  onCreateGrade,
}: IFormUserLevelPorps) => {
  const gradesAutocomplite = useMemo(() => {
    const isBlockedEdit = activePosition === null;
    return gradesAdvanced.map(
      (item) =>
        ({
          label: item.name,
          value: item.id,
          data: item,
          text: activePosition
            ? item.positions.find(
                (position) => position.position === activePosition.id,
              )?.education_link || undefined
            : undefined,
          isHideDelete: isBlockedEdit,
          isHideEdit: isBlockedEdit,
        } as unknown as ISelectedType<IGradeAndPositions>),
    );
  }, [
    positionGrades,
    positions,
    grades,
    gradesAdvanced,
    activePosition,
    gradesAdvancedPositionActive,
  ]);

  const gradesList = useMemo(() => {
    const isBlockedEdit = activePosition === null;
    return gradesAdvancedPositionActive.map(
      (item) =>
        ({
          label: item.name,
          value: item.id,
          data: item,
          text: activePosition
            ? item.positions.find(
                (position) => position.position === activePosition.id,
              )?.education_link || undefined
            : undefined,
          isHideDelete: isBlockedEdit,
          isHideEdit: isBlockedEdit,
        } as unknown as ISelectedType<IGradeAndPositions>),
    );
  }, [
    positionGrades,
    positions,
    grades,
    gradesAdvanced,
    activePosition,
    gradesAdvancedPositionActive,
  ]);

  const onCreateFieldGrade = useCallback(
    (value: string) => {
      if (activePosition) {
        onCreatePositionGrade({
          name: value,
          education_link: "",
          position: activePosition.id,
        });
      } else {
        onCreateGrade({ name: value });
      }
    },
    [grades, positions, positionGrades, activePosition],
  );

  const onSaveGrade = (_: string, link: string, data?: IGradeAndPositions) => {
    if (data && activePosition) {
      const position = data.positions.find(
        (item) => item.position === activePosition.id,
      );
      if (position) {
        onUpdateGrade(position.id, {
          possitionGrtadeId: position.position,
          gradeId: position.grade,
          education_link: link,
        });
      }
    }
  };

  const onDeletePositionGradeField = (value: IGradeAndPositions) => {
    const position = value.positions.find(
      (item) => item.position === activePosition?.id,
    );
    if (position) onDeletePositionGrade(position);
  };

  const onAutocompliteEvents = (
    type: "add" | "sub" | "clear",
    value?: null | ISelectedType<IGradeAndPositions>,
  ) => {
    if (activePosition) {
      if (type === "add" && value && value.data) {
        const item = value.data;
        onCreatePositionGrade(
          {
            name: item.name,
            education_link: "",
            position: activePosition.id,
          },
          value.data.id,
        );
      } else if (type === "sub" && value && value.data) {
        onDeletePositionGradeField(value.data);
      } else {
        console.log("ERROR autocomplite set item");
      }
    }
  };

  return (
    <div className={classes.shadow}>
      <form className={classes.form}>
        <div className={classes.formItem} data-type="user-knowledge">
          <div className={classes.fieldsRow}>
            <FormColumn<IEmplyeePosition>
              title={"Добавить должность"}
              onDeleteField={(value) => onDeletePosition(value)}
              onSaveItem={(title, link, data) => {
                onUpdatePosition({
                  title,
                  link,
                  data: data as IEmplyeePosition,
                });
              }}
              onClickItem={onClickPosition}
              activeItem={activePosition}
              data={positions.map(
                (item) =>
                  ({
                    label: item.name,
                    value: item.id,
                    data: item,
                    text: item.responsibilities_link,
                  } as unknown as ISelectedType<IEmplyeePosition>),
              )}
              onCreateField={(value) => onCreatePosition({ name: value })}
            />

            <FormColumn
              title={"Добавить грейд"}
              activeItem={activePosition as unknown as IGradeAndPositions}
              onDeleteField={onDeletePositionGradeField}
              data={gradesList}
              selected={gradesList}
              dataAutocomplite={gradesAutocomplite}
              onSaveItem={onSaveGrade}
              onCreateField={onCreateFieldGrade}
              autocomplite={activePosition !== null}
              sortListOff={activePosition !== null}
              onAutocompliteEvents={onAutocompliteEvents}
              readonlyName
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormUserLevel;
