import classes from "./StatusList.module.scss";

export const StatusList = ({ className = "" }: { className?: string }) => {
  return (
    <div className={`${classes.statusList} ${className}`}>
      <div className={`${classes.Vacation} ${classes.statusItem}`}>Отпуск</div>
      <div className={`${classes.Medical} ${classes.statusItem}`}>
        Больничный
      </div>
      <div className={`${classes.Truancy} ${classes.statusItem}`}>Прогул</div>
    </div>
  );
};
