import classes from "./Filters.module.scss";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import { UserRoleContext } from "context/RoleContext";
import { useSearchParams } from "react-router-dom";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Choice } from "forms/dto";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import { createParams } from "helpers/createParams";
import Button from "components/ui/button/Button";
import PlusFill from "components/ui/icons/PlusFill";
import Redact from "components/ui/icons/Redact";
import CalendarDatePicker from "../calendar-date-picker/CalendarDatePicker";

interface TFiltersProps {
  isSupervisorEditUserEmployment: boolean;
  setIsSupervisorEditUserEmployment: Dispatch<SetStateAction<boolean>>;
}

const Filters = ({
  isSupervisorEditUserEmployment,
  setIsSupervisorEditUserEmployment,
}: TFiltersProps) => {
  const { userRoleContext } = useContext(UserRoleContext);
  const { apiGet } = useApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [resetSelect, setResetSelect] = useState(false);
  const [projectsList, setProjectsList] = useState<Choice[] | null>(null);
  const [membersList, setMembersList] = useState<Choice[] | null>(null);
  const [positionList, setPositionList] = useState<Choice[] | null>(null);

  const onChangeDepartment = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { department: value.toString() }),
      );
    }
  };

  const onChangePosition = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { position: value.toString() }),
      );
    }
  };

  const onChangeProject = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { project: value.toString() }),
      );
    }
  };

  const onChangeEmployee = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, {
          employee: value.toString(),
        }),
      );
    }
  };

  useEffect(() => {
    const getProjectsList = async () => {
      const res = await apiGet(endpoints.planningChoices + "project/");
      setProjectsList(res.data);
    };

    const getMembersList = async () => {
      const res = await apiGet(endpoints.planningChoices + "employee/");
      setMembersList(res.data);
    };

    const getPositionList = async () => {
      const res = await apiGet(endpoints.planningChoices + "position/");
      setPositionList(res.data);
    };

    getMembersList();
    getProjectsList();
    getPositionList();
  }, []);

  useEffect(() => {
    if (resetSelect) setResetSelect(false);
  });

  useEffect(() => {
    setResetSelect(true);
  }, [searchParams]);

  const optionStyle = { fontSize: "1rem", fontWeight: 500, lineHeight: "20px" };

  return (
    <div className={[classes.container, "planning-filters"].join(" ")}>
      <div className={classes.selects}>
        {userRoleContext.data?.can_view_by_department && (
          <SelectWrapper
            reset={resetSelect}
            name="departments"
            placeholder="Подразделение"
            value={searchParams.get("department")}
            choices={[
              { name: "Все подразделения", label: "all", value: "all" },
              { name: "Белгород", label: "bel", value: "2" },
            ]}
            onChange={onChangeDepartment}
            searchable={true}
            optionStyle={optionStyle}
          />
        )}
        {positionList && (
          <SelectWrapper
            reset={resetSelect}
            name="positions"
            placeholder="Должность"
            value={Number(searchParams.get("position"))}
            choices={[
              { name: "Все должности", label: "all", value: "all" },
              ...positionList,
            ]}
            onChange={onChangePosition}
            searchable={true}
            optionStyle={optionStyle}
          />
        )}
        {projectsList && (
          <SelectWrapper
            reset={resetSelect}
            name="projects"
            placeholder="Проекты"
            value={Number(searchParams.get("project"))}
            choices={[
              { name: "Все проекты", label: "all", value: "all" },
              ...projectsList,
            ]}
            onChange={onChangeProject}
            searchable={true}
            optionStyle={optionStyle}
          />
        )}
        {membersList && (
          <SelectWrapper
            reset={resetSelect}
            name="employees"
            placeholder="Сотрудники"
            value={Number(searchParams.get("employee"))}
            choices={[
              { name: "Все сотрудники", label: "all", value: "all" },
              ...membersList,
            ]}
            onChange={onChangeEmployee}
            searchable={true}
            optionStyle={optionStyle}
          />
        )}
        {isSupervisorEditUserEmployment && (
          <CalendarDatePicker buttonClass={classes.calendar} />
        )}
      </div>
      <div className={classes.buttons}>
        {isSupervisorEditUserEmployment ? (
          <Button
            clazz={classes.button}
            onClick={() => setIsSupervisorEditUserEmployment(false)}
            success
          >
            Применить изменения
          </Button>
        ) : (
          <>
            <Button
              icon={<Redact />}
              clazz={classes.button}
              onClick={() => setIsSupervisorEditUserEmployment(true)}
            >
              Внести изменения
            </Button>
            <Button icon={<PlusFill />} success clazz={classes.buttonPlus}>
              Добавить проект
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Filters;
