import { useEffect, useMemo, useRef } from "react";
import classes from "./DashboardCalendar.module.scss";
import {
  getDaysInMonth,
  startOfMonth,
  endOfMonth,
  setDefaultOptions,
  format,
} from "date-fns";
import { ru } from "date-fns/locale";
import {
  ICalendarUserData,
  ICalendarUserItem,
  ManagementEmployeeType,
} from "types";
import { useApi } from "hooks/useApi/useApi";
import { useStateRequest } from "hooks/useStateRequest/useStateRequest";
import { Loader } from "components/ui/icons/Loader";

setDefaultOptions({ locale: ru });

const DashboardCalendar = ({
  data,
  date,
  users,
  loading,
  error,
}: {
  data: ICalendarUserData | null;
  date: Date;
  users: ManagementEmployeeType[];
  loading: boolean;
  error?: string | null;
}) => {
  const daysInMonth = getDaysInMonth(date);

  const daysAndWeek = useMemo(() => {
    const result: { day: number; week: string; isHoliday: boolean }[] = [];
    for (let i = 0; i < daysInMonth; i++) {
      const week = format(
        new Date(date.getFullYear(), date.getMonth(), i + 1),
        "EE",
        { locale: ru },
      ).slice(0, -1);

      result.push({
        day: i + 1,
        week: week === "су" ? "сб" : week,
        isHoliday: week === "су" || week === "вс",
      });
    }

    return result;
  }, [date, daysInMonth]);

  const countWorkDays = useMemo(() => {
    return daysAndWeek.filter((item) => item.isHoliday === false).length;
  }, [daysAndWeek, date]);

  const dateObjectRows = useMemo(() => {
    const obj: Record<string, ICalendarUserItem> = {};
    (data?.data || []).forEach((item) => {
      obj[item.pk.toString()] = item;
    });

    return obj;
  }, [users, data, date]);

  const onClickTime = (date: string | Date, employeeId: number) => {
    const start = format(date, "yyyy-MM-dd");
    const end = format(date, "yyyy-MM-dd");
    window.open(
      `/reports?employee=${employeeId}&date_after=${end}&date_before=${start}`,
    );
  };

  const onClickTimeTotal = (values: ICalendarUserItem) => {
    const start = format(values.worked_time[0].date, "yyyy-MM-dd");
    const end = format(
      values.worked_time[values.worked_time.length - 1].date,
      "yyyy-MM-dd",
    );
    window.open(
      `/reports?employee=${values.pk}&date_after=${end}&date_before=${start}`,
    );
  };

  return (
    <div>
      <table className={classes.tabel}>
        <tr>
          {daysAndWeek.map((item) => (
            <th
              key={item.day}
              className={item.isHoliday ? classes.holidayHeader : ""}
            >
              <span className={classes.week}> {item.week} </span>
              <span className={classes.day}> {item.day} </span>
            </th>
          ))}
          <th className={classes.tableTotal}>
            <div>{countWorkDays} рабочих дня</div>
            <div>Всего часов</div>
          </th>
        </tr>
        {users.length > 0 &&
          data &&
          !loading &&
          users.map((item, index) => (
            <tr key={index + "" + item.id}>
              {dateObjectRows[item.id].worked_time.map((worked, index) => (
                <td
                  className={`${
                    daysAndWeek[index]?.isHoliday ? classes.holiday : ""
                  } ${classes.workDateHover}`}
                  data-date={worked.date}
                  key={
                    dateObjectRows[item.id.toString()].pk + "-" + worked.date
                  }
                  onClick={() => onClickTime(worked.date, item.id)}
                >
                  {worked.worked_time_count}
                </td>
              ))}
              <td
                className={classes.tableTotal + " " + classes.workDateHover}
                onClick={() => onClickTimeTotal(dateObjectRows[item.id])}
              >
                {dateObjectRows[item.id].hours_count}
              </td>
            </tr>
          ))}
      </table>
      {loading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      {data === null && !loading && !error && (
        <div className={classes.empty}>Найдено записей 0</div>
      )}
      {!loading && !!error && <div className={classes.empty}>{error}</div>}
    </div>
  );
};

export const DashboardCalendarContainer = ({
  users,
  date,
}: {
  users: ManagementEmployeeType[];
  date: null | Date;
}) => {
  const initRef = useRef(false);
  const { apiGet } = useApi();
  const { loading, setLoading, setError, dataRequest, setDataRequest, error } =
    useStateRequest<ICalendarUserData | null>({ initData: null });

  const getCalendarData = async ({
    date,
  }: {
    date?: {
      start: string;
      end: string;
    };
  }): Promise<ICalendarUserData> => {
    try {
      let q = "";
      if (date) {
        q += `start_date=${date.start}&end_date=${date.end}`;
      }
      const res = await apiGet(
        `${process.env.REACT_APP_API_URL}/api/employee/employees/calendar/${
          q !== "" ? "?" + q : ""
        }`,
      );
      return res.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    initRef.current = true;
  }, []);

  useEffect(() => {
    if (!loading && initRef.current) {
      const start = format(startOfMonth(date || new Date()), "yyyy-MM-dd");
      const end = format(endOfMonth(date || new Date()), "yyyy-MM-dd");
      setLoading(true);
      setError(null);
      getCalendarData({
        date: {
          start,
          end,
        },
      })
        .then((res) => {
          setDataRequest(res);
        })
        .catch((e) => {
          setError(JSON.stringify(e));
        })
        .finally(() => setLoading(false));
    }
  }, [users, date]);

  return (
    <DashboardCalendar
      users={users}
      data={dataRequest}
      loading={loading}
      date={date || new Date()}
      error={error}
    />
  );
};
