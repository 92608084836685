interface IconProps {
  clazz?: string;
}

const CalendarOutline = ({ clazz }: IconProps) => {
  return (
    <svg
      width="16"
      className={clazz}
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6.00004V12C2 13.4728 3.19391 14.6667 4.66667 14.6667H11.3333C12.8061 14.6667 14 13.4728 14 12V6.00004M2 6.00004V5.00004C2 3.52728 3.19391 2.33337 4.66667 2.33337H11.3333C12.8061 2.33337 14 3.52728 14 5.00004V6.00004M2 6.00004H14M10.6667 1.33337V3.33337M5.33333 1.33337V3.33337"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CalendarOutline;
