import classes from "./Pagination.module.scss";
import ArrowBack from "assets/img/icons/arrow-prev.svg";
import ArrowNext from "assets/img/icons/arrow-next.svg";
import { PagesType } from "types";

type Props = {
  pages: PagesType;
  setPages: React.Dispatch<React.SetStateAction<PagesType>>;
  isLoading?: boolean;
};

const Pagination = ({ pages, setPages, isLoading }: Props) => {
  const firstItemOnPage =
    pages.items_per_page * pages.current_page_number - pages.items_per_page + 1;

  const lastItemOnPage =
    pages.items_per_page * pages.current_page_number < pages.total_items
      ? pages.items_per_page * pages.current_page_number
      : pages.total_items;

  const changePage = (way: string) => {
    switch (way) {
      case "next": {
        if (pages.current_page_number < pages.total_pages) {
          setPages({
            ...pages,
            current_page_number: pages.current_page_number + 1,
          });
        }
        break;
      }
      case "back": {
        if (pages.current_page_number > 1) {
          setPages({
            ...pages,
            current_page_number: pages.current_page_number - 1,
          });
        }
        break;
      }
    }
  };

  const goToPage = (value: string) => {
    setPages({
      ...pages,
      current_page_number: Number(value),
    });
  };

  return pages.total_pages > 1 ? (
    <div className={classes.container}>
      <div className={classes.currentPage}>
        Страница:
        <input
          type="number"
          className={classes.input}
          min="1"
          max={pages.total_pages}
          placeholder={String(pages.current_page_number)}
          onChange={(e) => goToPage(e.target.value)}
          value={pages.current_page_number}
          disabled={isLoading}
        />
      </div>
      <div className={classes.countPages}>
        {`${firstItemOnPage}-${lastItemOnPage} из ${pages.total_items}`}
      </div>
      <div className={classes.arrows}>
        <button
          type="button"
          disabled={isLoading}
          onClick={() => changePage("back")}
        >
          <img src={ArrowBack} alt="arrow back" className={classes.arrow} />
        </button>
        <button
          type="button"
          disabled={isLoading}
          onClick={() => changePage("next")}
        >
          <img src={ArrowNext} alt="arrow next" className={classes.arrow} />
        </button>
      </div>
    </div>
  ) : null;
};

export default Pagination;
