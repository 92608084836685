import classes from "./Dashboard.module.scss";
import { SELECT_OPTIONS } from "forms/components/simpleWidgets/select-input/SelectIntputStyles";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import { useState, useEffect, useMemo } from "react";
import UserCart from "../UserCart/UserCard";
import { useApi } from "hooks/useApi/useApi";
import { useSearchParams } from "react-router-dom";
import { ManagementEmployeeType } from "types";
import { ButtonGroup } from "components/ui/ButtonGroup/ButtonGroup";
import Paper from "components/ui/paper/Paper";
import { createParams } from "helpers/createParams";
import { DashboardSmallCalendar } from "../DashboardSmallCalendar/DashboardSmallCalendar";
import { useWindowSize } from "hooks/useWindowSize/useWindowSize";
import { SearchUsers } from "../SearchUsers/SearchUsers";
import { DashboardTableContainer } from "../DashboardTable/DashboardTable";
import { DashboardCalendarContainer } from "../DashboardCalendar/DashboardCalendar";
import { StatusList } from "../StatusList/StatusList";
import { setDefaultOptions } from "date-fns";
import { ru } from "date-fns/locale";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";

setDefaultOptions({ locale: ru });

enum DashboardTabContent {
  metrica = "metrica",
  calendar = "calendar",
}

const Dashboard = () => {
  const { width = window.innerWidth } = useWindowSize();
  const [dataTableRows, setDataTableRows] = useState<ManagementEmployeeType[]>(
    [],
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [resetSelect, setResetSelect] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [date, setDate] = useState<Date | null>(new Date());
  const [tab, setTab] = useState<DashboardTabContent>(
    DashboardTabContent.metrica,
  );
  const [role, setRole] = useState<null | string>(null);
  const { apiGet } = useApi();

  const media = useMemo(() => {
    return {
      isMobile: width < 768,
      isTablet: width >= 768 && width < 1280,
      isDesktop: width >= 1280,
    };
  }, [width]);

  const onChangeSelectedUsers = (value: string | number | Date | string[]) => {
    setSelectedUsers(value as string[]);
  };

  const onChangeDepartments = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { department: value.toString() }),
      );
    }
  };

  const onChangeUserType = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { user_type: value.toString() }),
      );
    }
  };

  const onChangeSubdivision = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { subdivision: value.toString() }),
      );
    }
  };

  const getManagementList = async () => {
    if (role) {
      searchParams.set("role", role);
    } else {
      searchParams.delete("role");
    }

    const res = await apiGet(
      `${process.env.REACT_APP_API_URL}/api/employee/employees`,
      searchParams,
    );
    res.data?.data && setDataTableRows(res.data.data);
  };

  useEffect(() => {
    getManagementList();
  }, []);

  useEffectOnlyOnUpdate(() => {
    getManagementList();
  }, [role]);

  useEffect(() => {
    setResetSelect(true);
  }, [searchParams]);

  useEffect(() => {
    if (resetSelect) setResetSelect(false);
  });

  const dashboardContent = {
    [DashboardTabContent.metrica]: (
      <DashboardTableContainer date={date} users={dataTableRows} />
    ),
    [DashboardTabContent.calendar]: (
      <DashboardCalendarContainer users={dataTableRows} date={date} />
    ),
  };

  return (
    <div>
      <div className={classes.dashboarHeader}>
        {media.isDesktop && (
          <Paper cls={{ paddingRoot: classes.buttonGroupContainer }}>
            <ButtonGroup
              clsx={{
                root: classes.buttonGroupRoot,
              }}
              defaultActive={DashboardTabContent.metrica}
              items={[
                {
                  label: "Метрики",
                  id: DashboardTabContent.metrica,
                  onClick: (value) => setTab(value as DashboardTabContent),
                },
                {
                  label: "Календарь",
                  id: DashboardTabContent.calendar,
                  onClick: (value) => setTab(value as DashboardTabContent),
                },
              ]}
            />
          </Paper>
        )}

        <Paper cls={{ paddingRoot: classes.dashboarHeaderActions }}>
          <div className={classes.dashboarHeaderActionsRow}>
            <div className={classes.dashboarHeaderActionsSelects}>
              {!media.isDesktop && (
                <SearchUsers
                  selectedUsers={selectedUsers}
                  onChangeSelectedUsers={onChangeSelectedUsers}
                  className={classes.searchUsersBorder}
                  role={role}
                  onChangeRole={setRole}
                />
              )}
              {!media.isDesktop && (
                <div className={classes.buttonGroupContainer}>
                  <ButtonGroup
                    clsx={{
                      root: classes.buttonGroupRoot,
                    }}
                    defaultActive={DashboardTabContent.metrica}
                    items={[
                      {
                        label: "Метрики",
                        id: DashboardTabContent.metrica,
                        onClick: (value) =>
                          setTab(value as DashboardTabContent),
                      },
                      {
                        label: "Календарь",
                        id: DashboardTabContent.calendar,
                        onClick: (value) =>
                          setTab(value as DashboardTabContent),
                      },
                    ]}
                  />
                </div>
              )}
              <SelectWrapper
                className={classes.dashboarHeaderActionsSelect}
                reset={resetSelect}
                name="subdivision"
                placeholder="Подразделение"
                value={searchParams.get("subdivision")}
                choices={[
                  { name: "Все подразделения", label: "all", value: "all" },
                  { name: "Белгород", label: "bel", value: "2" },
                ]}
                onChange={onChangeSubdivision}
                searchable={true}
                optionStyle={SELECT_OPTIONS}
                theme="outline"
              />
              <SelectWrapper
                className={classes.dashboarHeaderActionsSelect}
                optionStyle={SELECT_OPTIONS}
                reset={resetSelect}
                name="departments"
                placeholder="Отдел"
                value={searchParams.get("departments")}
                choices={[
                  { name: "Все подразделения", label: "all", value: "all" },
                  { name: "Белгород", label: "bel", value: "2" },
                ]}
                onChange={onChangeDepartments}
                searchable={true}
                theme="outline"
              />
              <SelectWrapper
                reset={resetSelect}
                name="user_type"
                placeholder="Тип персонала "
                value={searchParams.get("user_type")}
                choices={[
                  { name: "Все подразделения", label: "all", value: "all" },
                  { name: "Белгород", label: "bel", value: "2" },
                ]}
                onChange={onChangeUserType}
                searchable={true}
                optionStyle={SELECT_OPTIONS}
                theme="outline"
                className={classes.dashboarHeaderActionsSelect}
              />
              {tab === DashboardTabContent.calendar && (
                <StatusList className={classes.dashboardStatus} />
              )}
              {!media.isDesktop && (
                <div className={classes.dashboardSmallCalendar}>
                  <DashboardSmallCalendar
                    date={date}
                    onChangeDate={setDate}
                    modalOff={DashboardTabContent.calendar === tab}
                  />
                </div>
              )}
            </div>
            {media.isDesktop && (
              <DashboardSmallCalendar
                date={date}
                onChangeDate={setDate}
                modalOff={DashboardTabContent.calendar === tab}
              />
            )}
          </div>
        </Paper>
      </div>

      <div className={classes.dashboardContainer}>
        <aside className={classes.dashboardAside}>
          {media.isDesktop && (
            <SearchUsers
              selectedUsers={selectedUsers}
              onChangeSelectedUsers={onChangeSelectedUsers}
              role={role}
              onChangeRole={setRole}
            />
          )}

          <div className={classes.asideBody}>
            {dataTableRows.map((item, index) => (
              <UserCart
                avatar={item.photo}
                name={item.first_name + " " + item.last_name}
                position={item.position_name}
                key={item.slug}
                small={width <= 1300}
              />
            ))}
          </div>
        </aside>
        <main className={classes.dashboardMain}>{dashboardContent[tab]}</main>
      </div>
    </div>
  );
};

export default Dashboard;
