import classes from "./ReportUploading.module.scss";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import { endpoints } from "API/endpoints";
import { useApi } from "hooks/useApi/useApi";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import { ApiDataType } from "types";
import axios from "axios";
import { useEffect, useState } from "react";

interface ReportUploadingType {
  groupBy: string;
  params: {
    date_after?: string | null;
    date_before?: string | null;
    project?: string | number | null;
    employee?: string | number | null;
    ordering?: string;
  };
  fileName?: string;
}

const ReportUploading = ({
  groupBy,
  params,
  fileName,
}: ReportUploadingType) => {
  const [error, setError] = useState<string[]>();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSaccess, setIsSaccess] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>("");

  const { apiGet } = useApi();

  const upload = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    if (error) setError(undefined);
    setIsSending(true);
    const activeParams = Object.assign(
      {},
      params.date_after && { date_after: params.date_after },
      params.date_before && { date_before: params.date_before },
      params.project && { project: params.project },
      params.employee && { employee: params.employee },
      params.ordering && { ordering: params.ordering },
    );

    let res: ApiDataType<string, string[]>;

    if (fileType === "xlsx") {
      const accessToken = localStorage.getItem("accessToken");

      const headers = {
        Authorization: `Bearer ${accessToken}`, // Добавляем заголовок авторизации
      };

      // Запрос к API для получения файла
      axios({
        url: `${endpoints.reportXLXS}${groupBy}`,
        params: activeParams,
        method: "GET",
        responseType: "arraybuffer",
        headers: headers, // Передаем заголовок в запрос
      })
        .then((response) => {
          // Создание объекта Blob для скачивания
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);

          // Создание ссылки и эмуляция клика по ссылке для скачивания
          const a = document.createElement("a");
          a.href = url;
          a.download = `${fileName}.xlsx`;
          a.click();

          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Ошибка при загрузке файла:", error);
        });
    } else {
      res = await apiGet(`${endpoints.reportCsv}${groupBy}/`, activeParams);

      if (res.data) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${fileName || groupBy}-report.${fileType}`,
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsSaccess(true);
        setTimeout(() => {
          setIsSaccess(false);
        }, 250);
      } else {
        setError(res.errorMessage);
      }
    }

    setIsSending(false);
  };

  useEffect(() => {
    if (error) setError(undefined);
  }, [params]);

  return (
    <form className={classes.form} id="uploading" onSubmit={upload}>
      <div className={classes.wrapper}>
        <SelectWrapper
          form="uploading"
          name="fileType"
          placeholder="Формат"
          choices={[
            { name: "CSV", label: "CSV", value: "csv" },
            { name: "XLSX", label: "XLSX", value: "xlsx" },
          ]}
          onChange={(value) => {
            if (typeof value === "string") setFileType(value);
          }}
          required={true}
        />
        <button
          disabled={isSending}
          form="uploading"
          type="submit"
          className={
            isSaccess ? [classes.btn, classes.saccess].join(" ") : classes.btn
          }
        >
          Скачать
        </button>
      </div>
      {error
        ? error.map((i, idx) => (
            <ErrorComponent clazz={classes.error} key={idx}>
              {i}
            </ErrorComponent>
          ))
        : null}
    </form>
  );
};

export default ReportUploading;
