import Birthday from "components/ui/icons/Birthday";
import Mail from "components/ui/icons/Mail";
import Phone from "components/ui/icons/Phone";
import Telegram from "components/ui/icons/Telegram";
import classes from "./BigSizeUserComponent.module.scss";
import { OpenedBlocksContext } from "context/OpenedBlocks";
import { ProfileCardProps } from "../../ProfileCard";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import EmptyImage from "assets/img/empty-image.png";
import { ApiDataType } from "types";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { UserInfoContext } from "context/UserInfoContext";
import { useContext, useEffect, useState } from "react";

const BigSizeUserComponent = ({ nonCollapsibleBlock }: ProfileCardProps) => {
  const { isBlockOpen } = useContext(OpenedBlocksContext);
  const { userInfoContext } = useContext(UserInfoContext);

  let classNameContainer, classNameAvatar;

  if (!isBlockOpen) {
    classNameContainer = classes["container--logging"];
  } else if (nonCollapsibleBlock) {
    classNameContainer = [
      classes.container,
      classes["container--nonCollapsible"],
    ].join(" ");
  } else {
    classNameContainer = classes.container;
  }

  if (nonCollapsibleBlock) {
    classNameAvatar = classes.avatar;
  } else if (isBlockOpen.isProfileOpen) {
    classNameAvatar = [classes.avatar, classes["avatar--min"]].join(" ");
  } else {
    classNameAvatar = classes.avatar;
  }

  const [userInfo, setUserInfo] = useState<ApiDataType | null>(null);

  const speciality = [
    [["Роль:"], [userInfo?.data.role]],
    [["Уровень:"], [userInfo?.data.level]],
    [["Должность:"], [userInfo?.data.position]],
    [["Основная специальность:"], [userInfo?.data.position]],
  ];

  const location = [
    [["Офис:"], [userInfo?.data.company]],
    [["Подразделение:"], [userInfo?.data.department]],
    [["Часовой пояс:"], [userInfo?.data.timezone]],
    [
      ["Часы работы:"],
      [`${userInfo?.data.work_start_time}-${userInfo?.data.work_end_time}`],
    ],
  ];

  const { apiGet } = useApi();

  useEffect(() => {
    const getShortUserInfo = () => {
      if (userInfoContext) {
        setUserInfo(userInfoContext);
      }
    };

    const getFullUserInfo = async () => {
      const res = await apiGet(endpoints.fullCurrentUserInfo);
      setUserInfo(res);
    };

    if (!nonCollapsibleBlock) {
      if (isBlockOpen.isProfileOpen) {
        getFullUserInfo();
      } else {
        getShortUserInfo();
      }
    } else if (userInfoContext) {
      getFullUserInfo();
    }
  }, [isBlockOpen, userInfoContext]);

  const skeletonStyle: React.CSSProperties = nonCollapsibleBlock
    ? {
        display: "block",
        width: "292px",
        height: "860px",
      }
    : {
        display: "block",
        width: "100%",
        height: "100%",
        position: "absolute",
        left: 0,
        top: 0,
      };

  return userInfo ? (
    <div className={classNameContainer}>
      <img
        src={userInfo.data.photo ? userInfo.data.photo : EmptyImage}
        alt="user avatar"
        className={classNameAvatar}
      />
      <span className={classes.name}>
        {`${userInfo.data.first_name} ${userInfo.data.last_name}`}
      </span>
      <span className={classes.job}>{userInfo.data.position}</span>
      {isBlockOpen.isProfileOpen || nonCollapsibleBlock ? (
        <div className={classes.info}>
          <div className={classes.contacts}>
            <div className={classes.contacts__item}>
              <a
                href={`"mailTo:${userInfo.data.email}"`}
                target="_blank"
                rel="noreferrer"
              >
                <Mail clazz={classes.icon} />
                <span className={classes.contacts__text}>
                  {userInfo.data.email}
                </span>
              </a>
            </div>
            <div className={classes.contacts__item}>
              <a
                href={`tel:${userInfo.data.phone}`}
                target="_blank"
                rel="noreferrer"
              >
                <Phone clazz={classes.icon} />
                <span className={classes.contacts__text}>
                  {userInfo.data.phone}
                </span>
              </a>
            </div>
            <div className={classes.contacts__item}>
              <a
                href={`https://t.me/${userInfo.data.telegram}`}
                target="_blank"
                rel="noreferrer"
              >
                <Telegram clazz={`${classes.icon} ${classes["icon--fill"]}`} />
                <span
                  className={classes.contacts__text}
                >{`@${userInfo.data.telegram}`}</span>
              </a>
            </div>
            <div className={classes.contacts__item}>
              <Birthday clazz={classes.icon} />
              <div className={classes.birthday}>
                <span className={classes.contacts__text}>День Рождения</span>
                <span className={classes.contacts__text}>
                  {userInfo.data.birthday}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.speciality}>
            {speciality.map((item, idx) => {
              return (
                <div key={idx} className={classes.speciality__item}>
                  <span className={classes.itemTitle}>{item[0]}</span>
                  <span className={classes.itemValue}>{item[1]}</span>
                </div>
              );
            })}
          </div>
          <div className={classes.location}>
            {location.map((item, idx) => {
              return (
                <div key={idx} className={classes.location__item}>
                  <span className={classes.itemTitle}>{item[0]}</span>
                  <span className={classes.itemValue}>{item[1]}</span>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  ) : (
    <Skeleton style={skeletonStyle} />
  );
};

export default BigSizeUserComponent;
