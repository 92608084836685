export const createParams = (
  searchParams: URLSearchParams,
  params: Record<string, string>,
) => {
  const newSearchParams = new URLSearchParams(searchParams);
  Object.keys(params).forEach((key) => {
    if (params[key] === "all") {
      newSearchParams.delete(key);
    } else {
      newSearchParams.set(key, params[key]);
    }
  });
  return newSearchParams;
};

export const getSearchParam = (
  searchParams: URLSearchParams,
  key: string,
  parse = false,
) => {
  const value = searchParams.get(key);
  if (value) {
    return parse ? JSON.parse(value) : value;
  }

  return null;
};
