import { useApi } from "hooks/useApi/useApi";
import { useStateRequest } from "hooks/useStateRequest/useStateRequest";
import {
  useContext,
  useEffect,
  useMemo,
  createContext,
  ReactNode,
  useRef,
  useState,
  useCallback,
} from "react";
import { ICompetenceCategory } from "types";

export type DeletePathFormExpertiselType = "competence_category" | "competence";

const endponints = {
  competence_category: "employee/competence_category/",
  competence: "employee/competence/",
};

const QualificationFormUserExpertiseContext = createContext<{
  isLoadingCompetences: boolean;
  setLoadingCompetences: (value: boolean) => void;
  competences: ICompetenceCategory[];
  setCompetences: React.Dispatch<React.SetStateAction<ICompetenceCategory[]>>;
  competenceCategorys: ICompetenceCategory[];
  setCompetenceCategorys: React.Dispatch<
    React.SetStateAction<ICompetenceCategory[]>
  >;
  isLoadingCompetenceCategorys: boolean;
  setLoadingCompetenceCategorys: (value: boolean) => void;
  getCompetenceCategorys: (
    search?: URLSearchParams,
  ) => Promise<ICompetenceCategory[]>;
  getCompetences: (
    search?: URLSearchParams,
    category?: string | number,
  ) => Promise<ICompetenceCategory[]>;
} | null>(null);

export const QualificationFormUserExpertiseContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    dataRequest: competences,
    setDataRequest: setCompetences,
    loading: isLoadingCompetences,
    setLoading: setLoadingCompetences,
  } = useStateRequest<ICompetenceCategory[]>({ initData: [] });
  const {
    dataRequest: competenceCategorys,
    setDataRequest: setCompetenceCategorys,
    loading: isLoadingCompetenceCategorys,
    setLoading: setLoadingCompetenceCategorys,
  } = useStateRequest<ICompetenceCategory[]>({ initData: [] });
  const { apiGet } = useApi();

  const getCompetenceCategorys = async (
    search?: URLSearchParams,
  ): Promise<ICompetenceCategory[]> => {
    try {
      const response = await apiGet(
        `${process.env.REACT_APP_API_URL}/api/employee/competence_category`,
        search,
      );
      return response.data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const getCompetences = async (
    search?: URLSearchParams,
    category?: string | number,
  ): Promise<ICompetenceCategory[]> => {
    try {
      const response = await apiGet(
        `${process.env.REACT_APP_API_URL}/api/employee/competence/${
          category ? "?category=" + category : ""
        }`,
        search,
      );
      return response.data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  useEffect(() => {
    setLoadingCompetences(true);
    setLoadingCompetenceCategorys(true);
    Promise.all([getCompetenceCategorys(), getCompetences()])
      .then((res) => {
        const [competenceCategorys, competences] = res;
        setCompetences(competences);
        setCompetenceCategorys(competenceCategorys);
      })
      .finally(() => {
        setLoadingCompetences(false);
        setLoadingCompetenceCategorys(false);
      });
  }, []);

  return (
    <QualificationFormUserExpertiseContext.Provider
      value={{
        competences,
        setCompetences,
        isLoadingCompetences,
        setLoadingCompetences,
        competenceCategorys,
        setCompetenceCategorys,
        isLoadingCompetenceCategorys,
        setLoadingCompetenceCategorys,
        getCompetenceCategorys,
        getCompetences,
      }}
    >
      {children}
    </QualificationFormUserExpertiseContext.Provider>
  );
};

export const useQualificationFormUserExpertiseLogic = () => {
  const context = useContext(QualificationFormUserExpertiseContext);
  const [stackCacheCompetences, setStackCacheCompetences] = useState<
    Record<number, ICompetenceCategory[]>
  >({});
  const init = useRef<boolean>(false);
  const [activeCompetenceCategory, setActiveCompetenceCategory] =
    useState<null | ICompetenceCategory>(null);
  const { apiDelete, apiPatch, apiPost, apiPut } = useApi();

  if (!context) {
    throw new Error("QualificationFormUserExpertiseContext was not provided");
  }

  const {
    competences,
    isLoadingCompetences,
    isLoadingCompetenceCategorys,
    competenceCategorys,
    setLoadingCompetences,
    setLoadingCompetenceCategorys,
    setCompetenceCategorys,
    setCompetences,
  } = context;

  const setLoadingAll = (value: boolean) => {
    setLoadingCompetences(value);
    setLoadingCompetenceCategorys(value);
  };

  const onDeleteCompetenceCategory = async (
    value: ICompetenceCategory,
    path: DeletePathFormExpertiselType = "competence_category",
  ) => {
    try {
      await apiDelete(
        `${process.env.REACT_APP_API_URL}api/${endponints[path]}${value.id}/`,
      );

      if (path === "competence") {
        setCompetences((prev) => prev.filter((item) => item.id !== value.id));
      } else {
        setCompetenceCategorys((prev) =>
          prev.filter((item) => item.id !== value.id),
        );
      }

      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onUpdateCompetence = async (
    value: {
      title: string;
      link: string;
      data: ICompetenceCategory;
    },
    path: DeletePathFormExpertiselType = "competence_category",
  ): Promise<ICompetenceCategory> => {
    const updateCompetence = (list: ICompetenceCategory[]) => {
      const arr = [...list];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === value.data.id) {
          arr[i].education_link = value.link;
          arr[i].name = value.title;
          break;
        }
      }
      return arr;
    };

    try {
      const respnse = await apiPatch(
        `${process.env.REACT_APP_API_URL}api/${endponints[path]}${value.data.id}/`,
        {
          education_link: value.link,
          name: value.title,
        },
      );

      if (path === "competence") {
        setCompetences(updateCompetence(competences));
      } else {
        setCompetenceCategorys(updateCompetence(competenceCategorys));
      }

      return respnse.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onSetCategoryOfCompetence = useCallback(
    async (
      id: number,
      category: number | null,
      options?: {
        creted?: ICompetenceCategory;
        deleteCategoryId?: number;
      },
    ): Promise<{ id: number; category: number }> => {
      try {
        const response = await apiPut(
          `${process.env.REACT_APP_API_URL}api/employee/competence/${id}/category/`,
          {
            category,
          },
        );

        if (category) {
          let competence = stackCacheCompetences[0].find(
            (item) => item.id === response.data.id,
          );
          if (
            competence === undefined &&
            options &&
            options.creted === undefined
          ) {
            throw new Error(
              "Not found the competence in the cache. Created undefined",
            );
          } else if (competence === undefined && options && options.creted) {
            competence = options.creted;
          } else if (competence === undefined) {
            throw new Error("Not found the competence in the cache");
          }
          addStackCacheCompetences(response.data.category, [
            competence,
            ...stackCacheCompetences[response.data.category],
          ]);
          setCompetences((prev) => [competence!, ...prev]);
        } else {
          const filters = competences.filter((item) => item.id !== id);
          setCompetences(filters);
          if (options && options.deleteCategoryId) {
            addStackCacheCompetences(options.deleteCategoryId, filters);
          }
        }

        return response.data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    [competenceCategorys, competences, stackCacheCompetences],
  );

  const onCreateCompetenceCategoryItem = async (values: {
    name: string;
    education_link?: string;
  }): Promise<ICompetenceCategory> => {
    try {
      const response = await apiPost(
        `${process.env.REACT_APP_API_URL}api/employee/competence_category/`,
        {
          name: values.name,
        },
      );
      setCompetenceCategorys((prev) => [response.data, ...prev]);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const onCreateCompetenceItem = async (
    values: { name: string; education_link?: string },
    category?: null | number,
  ): Promise<ICompetenceCategory> => {
    try {
      const response = await apiPost(
        `${process.env.REACT_APP_API_URL}api/employee/competence/`,
        {
          name: values.name,
        },
      );

      if (category) {
        const item = {
          id: response.data.id,
          name: values.name,
          education_link: values?.education_link || "",
        };
        addStackCacheCompetences(0, [item, ...stackCacheCompetences[0]]);
        onSetCategoryOfCompetence(response.data.id, category, { creted: item });
      } else {
        setCompetences((prev) => [response.data, ...prev]);
      }

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const onGetCompetencesByCategory = async (
    category: number,
  ): Promise<ICompetenceCategory[]> => {
    try {
      const response = await context.getCompetences(
        new URLSearchParams(),
        category,
      );
      addStackCacheCompetences(category, response);
      setCompetences(response);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const isLoadingAll = useMemo(() => {
    return isLoadingCompetenceCategorys || isLoadingCompetences;
  }, [isLoadingCompetenceCategorys, isLoadingCompetences]);

  //TODO: rename function
  const addStackCacheCompetences = (
    id: number,
    data: ICompetenceCategory[],
  ) => {
    setStackCacheCompetences((prev) => ({ ...prev, [id]: data }));
  };

  useEffect(() => {
    init.current = true;
  }, []);

  useEffect(() => {
    if (!isLoadingAll && activeCompetenceCategory === null) {
      addStackCacheCompetences(0, competences);
    }
  }, [isLoadingAll, competences]);

  useEffect(() => {
    if (!isLoadingCompetenceCategorys && activeCompetenceCategory === null) {
      context.getCompetences();
    } else if (
      activeCompetenceCategory &&
      init.current &&
      !isLoadingCompetenceCategorys
    ) {
      onGetCompetencesByCategory(activeCompetenceCategory.id);
    }
  }, [activeCompetenceCategory]);

  return {
    ...context,
    competences,
    isLoadingCompetences,
    isLoadingCompetenceCategorys,
    competenceCategorys,
    isLoadingAll,
    setLoadingAll,
    onDeleteCompetenceCategory,
    onUpdateCompetence,
    activeCompetenceCategory,
    setActiveCompetenceCategory,
    onGetCompetencesByCategory,
    onCreateCompetenceCategoryItem,
    cacheCompetences: stackCacheCompetences,
    onSetCategoryOfCompetence,
    onCreateCompetenceItem,
  };
};
