import { useCallback, useState } from "react";
import classes from "../ManegementForms.module.scss";
import FormUserExpertise from "../components/FormUserExpertise";
import FormUserLevel from "../components/FormUserLevel";
import {
  DeletePathFormExpertiselType,
  useQualificationFormUserExpertiseLogic,
} from "components/management/ManegmentForms/QualificationForm/context/QualificationFormUserExpertiseLogic";
import { useQualificationFormUserLevelLogic } from "./context/QualificationFormUserLevelLogic";

type Form = "user-expertise" | "user-level";

export const QualificationForm = () => {
  const {
    competenceCategorys,
    setCompetenceCategorys,
    competences,
    setCompetences,
    onDeleteCompetenceCategory,
    onUpdateCompetence,
    setActiveCompetenceCategory,
    activeCompetenceCategory,
    onCreateCompetenceCategoryItem,
    cacheCompetences,
    onSetCategoryOfCompetence,
    onCreateCompetenceItem,
  } = useQualificationFormUserExpertiseLogic();

  const {
    positions,
    grades,
    activePosition,
    gradesFiltredPosition,
    setActivePosition,
    onCreatePosition,
    onCreatePositionGrade,
    onUpdatePositionGrade,
    onUpdatePosition,
    onDeletePosition,
    onDeletePositionGrade,
    gradesTitleKeys,
    gradesAdvanced,
    gradesAdvancedPositionActive,
    onCreateGrade,
  } = useQualificationFormUserLevelLogic();

  const [formTab, setFormTab] = useState<Form>("user-expertise");
  const FORM_TABS = {
    "user-expertise": (
      <FormUserExpertise
        competenceCategorys={competenceCategorys}
        setCompetenceCategorys={setCompetenceCategorys}
        competences={competences}
        setCompetences={setCompetences}
        onDeleteField={(value, path) =>
          onDeleteCompetenceCategory(
            value,
            path as DeletePathFormExpertiselType,
          )
        }
        onUpdateField={onUpdateCompetence}
        onClickCategory={setActiveCompetenceCategory}
        activeCategory={activeCompetenceCategory}
        onCreateCategory={onCreateCompetenceCategoryItem}
        competencesStack={cacheCompetences}
        onSetCategoryOfCompetence={onSetCategoryOfCompetence}
        onCreateCompetence={onCreateCompetenceItem}
      />
    ),
    "user-level": (
      <FormUserLevel
        positions={positions}
        grades={grades}
        activePosition={activePosition}
        positionGrades={gradesFiltredPosition}
        onClickPosition={setActivePosition}
        onCreatePosition={onCreatePosition}
        onCreatePositionGrade={onCreatePositionGrade}
        onUpdatePosition={onUpdatePosition}
        onUpdateGrade={onUpdatePositionGrade}
        onDeletePosition={onDeletePosition}
        onDeletePositionGrade={onDeletePositionGrade}
        gradesTitleKeys={gradesTitleKeys}
        gradesAdvanced={gradesAdvanced}
        gradesAdvancedPositionActive={gradesAdvancedPositionActive}
        onCreateGrade={onCreateGrade}
      />
    ),
  };

  return (
    <div className={classes.body}>
      <div className={`${classes.btnGroup} ${classes.btnGroupPadding}`}>
        <button
          className={`${classes.btnGroupItem} ${
            formTab === "user-expertise" ? classes.active : ""
          }`}
          onClick={() => setFormTab("user-expertise")}
        >
          Область знаний сотрудника
        </button>
        <button
          className={`${classes.btnGroupItem} ${
            formTab === "user-level" ? classes.active : ""
          }`}
          onClick={() => setFormTab("user-level")}
        >
          Должность и грейд
        </button>
      </div>

      {FORM_TABS[formTab]}
    </div>
  );
};

export default QualificationForm;
