import { useState } from "react";
import classes from "./ButtonGroup.module.scss";

export const ButtonGroup = ({
  defaultActive,
  items,
  clsx,
}: {
  defaultActive?: string;
  clsx?: {
    button?: string;
    root?: string;
  };
  items: {
    label: string;
    id: string | number;
    onClick?: (value: string | number) => void;
  }[];
}) => {
  const [active, setActive] = useState<string | number>(defaultActive || "");
  const { button: clbutton = "", root: clroot = "" } = clsx || {};
  return (
    <div className={`${classes.btnGroup} ${classes.btnGroupPadding} ${clroot}`}>
      {items.map((item) => (
        <button
          key={item.id}
          className={`${classes.btnGroupItem} ${
            active === item.id ? classes.active : ""
          } ${clbutton}`}
          onClick={() => {
            setActive(item.id);
            item.onClick && item?.onClick(item.id);
          }}
          type="button"
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};
