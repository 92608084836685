import { ICompetenceCategory } from "types";
import classes from "../ManegementForms.module.scss";
import FormColumn, { ISelectedType } from "./FormColumn";
import { DeletePathFormExpertiselType } from "components/management/ManegmentForms/QualificationForm/context/QualificationFormUserExpertiseLogic";
import { useCallback, useMemo, useState } from "react";
import Popup from "components/common/popup/Popup";
import Button from "components/ui/button/Button";
import Modal from "components/common/modal/Modal";

interface IFormUserExpertiseProps {
  competences: ICompetenceCategory[];
  setCompetences: (value: ICompetenceCategory[]) => void;
  competenceCategorys: ICompetenceCategory[];
  setCompetenceCategorys: (value: ICompetenceCategory[]) => void;
  onDeleteField: (
    value: ICompetenceCategory,
    path?: string,
  ) => Promise<unknown>;
  onClickCategory: React.Dispatch<
    React.SetStateAction<ICompetenceCategory | null>
  >;
  activeCategory: ICompetenceCategory | null;
  onUpdateField: (
    value: {
      title: string;
      link: string;
      data: ICompetenceCategory;
    },
    path?: DeletePathFormExpertiselType,
  ) => Promise<ICompetenceCategory>;
  onCreateCategory: (values: {
    name: string;
    education_link?: string;
  }) => Promise<ICompetenceCategory>;
  competencesStack: Record<number, ICompetenceCategory[]>;
  onSetCategoryOfCompetence: (
    id: number,
    category: number | null,
  ) => Promise<{ id: number; category: number | null }>;
  onCreateCompetence: (
    values: {
      name: string;
      education_link?: string;
    },
    category?: null | number,
  ) => Promise<ICompetenceCategory>;
}

const DeletePopup = ({
  title,
  dataDelete,
  setDelete,
  onDelete,
}: {
  title: string;
  dataDelete: ICompetenceCategory | null;
  setDelete: (value: ICompetenceCategory | null) => void;
  onDelete: (value: ICompetenceCategory) => void;
}) => {
  return (
    <Popup clazz={classes.popup}>
      <div>
        <p className={classes.popupTitle}>{title}</p>
        <div className={classes.popupActions}>
          <Button onClick={() => setDelete(null)}>Отменить</Button>
          {dataDelete && (
            <Button
              onClick={() => {
                onDelete(dataDelete);
                setDelete(null);
              }}
              theme="fill"
            >
              Удалить
            </Button>
          )}
        </div>
      </div>
    </Popup>
  );
};

const FormUserExpertise = ({
  competences,
  setCompetences,
  competenceCategorys,
  setCompetenceCategorys,
  onDeleteField,
  onUpdateField,
  activeCategory,
  onClickCategory,
  onCreateCategory,
  competencesStack,
  onSetCategoryOfCompetence,
  onCreateCompetence,
}: IFormUserExpertiseProps) => {
  const [competenceDelete, setCompetenceDelete] =
    useState<ICompetenceCategory | null>(null);
  const [competenceCategoryDelete, setCompetenceCategoryDelete] =
    useState<ICompetenceCategory | null>(null);

  const competencesList = useMemo(() => {
    return competences.map(
      (item) =>
        ({
          label: item.name,
          value: item.id,
          data: item,
          text: item.education_link,
        } as unknown as ISelectedType<ICompetenceCategory>),
    );
  }, [competences, competenceCategorys, competencesStack]);

  const onAutocompliteEvents = (
    type: "add" | "sub" | "clear",
    value?: null | ISelectedType<ICompetenceCategory>,
  ) => {
    if (type === "add" && value && value?.data && activeCategory) {
      onSetCategoryOfCompetence(value.data.id, activeCategory.id);
    } else if (type === "sub" && value) {
      onSetCategoryOfCompetence(value.data!.id!, null);
    } else {
      console.log("ERROR autocomplite set item");
    }
  };

  const onCreateCompetenceField = useCallback(
    (value: string) => {
      onCreateCompetence(
        { name: value },
        activeCategory ? activeCategory.id : null,
      );
    },
    [activeCategory, competences, competenceCategorys],
  );

  const onDeleteFieldCompetence = useCallback(
    (value: ICompetenceCategory) => {
      if (activeCategory) {
        return onSetCategoryOfCompetence(value.id, null);
      } else {
        return onDeleteField(value, "competence");
      }
    },
    [activeCategory, competences, competenceCategorys, competenceDelete],
  );

  return (
    <div className={classes.shadow}>
      <form className={classes.form}>
        <div className={classes.formItem} data-type="user-knowledge">
          <div className={classes.fieldsRow}>
            <FormColumn<ICompetenceCategory>
              title={"Добавить область знания"}
              onDeleteField={setCompetenceCategoryDelete}
              onSaveItem={(title, link, data) => {
                onUpdateField(
                  {
                    title,
                    link,
                    data: data as ICompetenceCategory,
                  },
                  "competence_category",
                );
              }}
              onClickItem={(item) => onClickCategory(item)}
              activeItem={activeCategory}
              data={competenceCategorys.map(
                (item) =>
                  ({
                    label: item.name,
                    value: item.id,
                    data: item,
                    text: item.education_link,
                  } as unknown as ISelectedType<ICompetenceCategory>),
              )}
              onCreateField={(value) => onCreateCategory({ name: value })}
            />
            <FormColumn
              activeItem={
                activeCategory
                  ? { ...activeCategory, id: activeCategory.id * 7345 }
                  : null
              }
              title={"Добавить навык"}
              onDeleteField={setCompetenceDelete}
              data={competencesList}
              selected={competencesList}
              dataAutocomplite={(competencesStack[0] || []).map(
                (item) =>
                  ({
                    label: item.name,
                    value: item.id,
                    data: item,
                    text: item.education_link,
                  } as unknown as ISelectedType<ICompetenceCategory>),
              )}
              onSaveItem={(title, link, data) => {
                onUpdateField(
                  {
                    title,
                    link,
                    data: data as ICompetenceCategory,
                  },
                  "competence",
                );
              }}
              onCreateField={onCreateCompetenceField}
              autocomplite={activeCategory !== null}
              sortListOff={activeCategory !== null}
              onAutocompliteEvents={onAutocompliteEvents}
            />
          </div>
        </div>
      </form>

      <Modal
        hide={() => setCompetenceDelete(null)}
        isShown={Boolean(competenceDelete)}
        closeIcon={<div />}
        clsx={{
          close: classes.modalClose,
          body: classes.modal,
        }}
      >
        <DeletePopup
          title={"Удалить выбранный навык?"}
          setDelete={setCompetenceDelete}
          dataDelete={competenceDelete}
          onDelete={onDeleteFieldCompetence}
        />
      </Modal>

      <Modal
        hide={() => setCompetenceCategoryDelete(null)}
        isShown={Boolean(competenceCategoryDelete)}
        closeIcon={<div />}
        clsx={{
          close: classes.modalClose,
          body: classes.modal,
        }}
      >
        <DeletePopup
          title={"Удалить выбранную область знания?"}
          setDelete={setCompetenceCategoryDelete}
          dataDelete={competenceCategoryDelete}
          onDelete={onDeleteField}
        />
      </Modal>
    </div>
  );
};

export default FormUserExpertise;
