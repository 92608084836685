import classes from "./JournalItem.module.scss";
import Arrow from "components/ui/icons/Arrow";
import Redact from "components/ui/icons/Redact";
import Delete from "components/ui/icons/Delete";
import Check from "components/ui/icons/Check";
import CrossFill from "components/ui/icons/CrossFill";
import "./JournalEditorItem.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import EditorPreview from "components/common/editor-preview/EditorPreview";
import ErrorComponent from "components/common/error-component/ErrorComponent";
import Popup from "components/common/popup/Popup";
import { ApiDataType } from "types";
import { useRef, useState } from "react";

interface JournalItemProps {
  note: {
    author: {
      first_name: string;
      id: number;
      last_name: string;
      photo: string | null;
      position: string;
    };
    date: string;
    id: number;
    project: number;
    text: string;
    error?: string[];
  };
  redaktPermit: boolean;
  redactCallback: (
    noteId: number,
    action: string,
    text?: string,
  ) => Promise<ApiDataType<string, string[]> | undefined>;
}

const JournalItem = ({
  note,
  redactCallback,
  redaktPermit,
}: JournalItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRedact, setIsRedact] = useState(false);
  const [isConfirmBlock, setIsConfirmBlock] = useState(false);
  const [value, setValue] = useState(note.text);
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState<string[] | null>(null);

  const editorRef = useRef<Editor>(null);

  const confirm = async (id: number, action: string) => {
    const res = await redactCallback(id, action);
    if (res?.errorMessage.length) {
      setError(res.errorMessage);
    }
  };

  const save = async (id: number, action: string) => {
    const editorInstance = editorRef.current?.getInstance();
    if (editorInstance) {
      setValue(editorInstance?.getMarkdown());
      const res = await redactCallback(
        id,
        action,
        editorInstance?.getMarkdown(),
      );
      if (res?.errorMessage.length) {
        setError(res.errorMessage);
      } else {
        setIsRedact(false);
      }
    }
  };

  const onEditorChange = () => {
    if (editorRef.current?.getInstance().getMarkdown() && isDisabled) {
      setIsDisabled(false);
    } else if (!editorRef.current?.getInstance().getMarkdown() && !isDisabled) {
      setIsDisabled(true);
    }
  };

  return (
    <div
      className={
        isOpen && !isConfirmBlock
          ? [
              classes.entry,
              classes["entry--open"],
              "editor-wrapper--open",
            ].join(" ")
          : isOpen && isConfirmBlock
          ? [
              classes.entry,
              classes["entry--open"],
              classes["entry--confirm"],
            ].join(" ")
          : classes.entry
      }
    >
      <p className={classes["entry-title"]}>
        <span className={classes["entry-title__date"]}>
          {note.date.split(",")[0]}
        </span>
        <span className={classes["entry-title__user"]}>
          {note.author.first_name} {note.author.last_name}
        </span>
      </p>
      <div
        className={[
          classes.editor,
          "journal-item-editor",
          "editor-global",
        ].join(" ")}
      >
        {isRedact ? (
          <>
            <Editor
              onChange={onEditorChange}
              initialValue={value || " "}
              previewStyle="vertical"
              height={"auto"}
              minHeight={"60px"}
              initialEditType="wysiwyg"
              useCommandShortcut={true}
              ref={editorRef}
              placeholder="Добавьте запись"
              usageStatistics={false}
              toolbarItems={[
                [
                  "heading",
                  "bold",
                  "italic",
                  "strike",
                  "link",
                  "ul",
                  "ol",
                  "quote",
                  "image",
                ],
              ]}
            />
            <div className={classes.editor__controls}>
              <button
                title={"Сохранить"}
                disabled={isDisabled}
                type="button"
                className={classes["save"]}
                onClick={() => save(note.id, "redact")}
              >
                <Check clazz={classes["save__icon"]} />
              </button>
              <button
                title={"Отмена"}
                type="button"
                className={classes["cancel"]}
                onClick={() => setIsRedact(false)}
              >
                <CrossFill clazz={classes["cancel__icon"]} />
              </button>
            </div>
          </>
        ) : (
          <EditorPreview value={value} open={isOpen} />
        )}
      </div>
      {!isRedact && !isConfirmBlock && (
        <button
          title={isOpen ? "Свернуть" : "Подробнее"}
          type="button"
          className={classes["arrow__button"]}
          onClick={() => {
            setIsOpen(!isOpen);
            setIsRedact(false);
            setIsConfirmBlock(false);
          }}
        >
          <Arrow clazz={classes["arrow__icon"]} />
        </button>
      )}
      {isOpen && !isRedact && (
        <div className={classes.controls}>
          {isConfirmBlock ? (
            <div className={classes.confirm}>
              <button
                type="button"
                title="Подтвердить удаление"
                className={classes.check}
                onClick={() => confirm(note.id, "delete")}
              >
                <Check clazz={classes.check__icon} />
              </button>
              <button
                type="button"
                title="Отмена"
                className={classes.cross}
                onClick={() => setIsConfirmBlock(false)}
              >
                <CrossFill clazz={classes.cross__icon} />
              </button>
            </div>
          ) : (
            redaktPermit && (
              <div className={classes.change}>
                <button
                  type="button"
                  title="Редактировать"
                  className={classes.redact}
                  onClick={() => setIsRedact(true)}
                >
                  <Redact clazz={classes.redact__icon} />
                </button>
                <button
                  type="button"
                  title="Удалить"
                  className={classes.delete}
                  onClick={() => setIsConfirmBlock(true)}
                >
                  <Delete clazz={classes.delete__icon} />
                </button>
              </div>
            )
          )}
        </div>
      )}
      {error?.length && (
        <Popup clazz={classes.popup} clickCallback={setError}>
          {error.map((i, idx) => {
            return <ErrorComponent key={idx}>{i}</ErrorComponent>;
          })}
        </Popup>
      )}
    </div>
  );
};

export default JournalItem;
