import Button from "components/ui/button/Button";
import classes from "../ManegementForms.module.scss";
import FormColumn, { ISelectedType } from "./FormColumn";
import { useQualificationFormUserExpertiseLogic } from "../QualificationForm/context/QualificationFormUserExpertiseLogic";
import { useMemo } from "react";
import { ICompetenceCategory } from "types";

export interface IItemUserKnowledgeSelect {
  id: number;
  category: ICompetenceCategory;
  options: ICompetenceCategory[];
}

export type IUserKnowledgeType = Record<number, IItemUserKnowledgeSelect>;

const FormUserKnowledge = ({
  selected,
  updateSelected,
}: {
  selected: IUserKnowledgeType;
  updateSelected: (
    value: IItemUserKnowledgeSelect,
    options?: { delete?: boolean },
  ) => void;
}) => {
  const {
    competenceCategorys,
    competences,
    activeCompetenceCategory,
    setActiveCompetenceCategory,
  } = useQualificationFormUserExpertiseLogic();
  const competenceCategorysList = useMemo(() => {
    return competenceCategorys.map(
      (item) =>
        ({
          label: item.name,
          data: item,
          value: item.id,
        } as unknown as ISelectedType<ICompetenceCategory>),
    );
  }, [competenceCategorys, activeCompetenceCategory]);

  const competencesList = useMemo(() => {
    return competences.map(
      (item) =>
        ({
          label: item.name,
          value: item.id,
          data: item,
          text: item.education_link,
        } as unknown as ISelectedType<ICompetenceCategory>),
    );
  }, [competences, competenceCategorys, activeCompetenceCategory]);

  const selectedActive = useMemo(() => {
    if (activeCompetenceCategory === null) {
      return null;
    }
    return selected[activeCompetenceCategory.id];
  }, [competences, competenceCategorys, activeCompetenceCategory, selected]);

  const selectedCategorys = useMemo(() => {
    return Object.keys(selected).map(
      (key) =>
        ({
          label: selected[parseInt(key)].category.name,
          value: selected[parseInt(key)].category.id,
          data: selected[parseInt(key)],
        } as unknown as ISelectedType<ICompetenceCategory>),
    );
  }, [competenceCategorys, selected]);

  const selectedOptions = useMemo(() => {
    if (selectedActive === null) {
      return [];
    }

    return selectedActive.options.map(
      (item) =>
        ({
          label: item.name,
          value: item.id,
          data: item,
          text: item.education_link,
        } as unknown as ISelectedType<ICompetenceCategory>),
    );
  }, [selectedActive, selected, activeCompetenceCategory]);

  return (
    <div className={classes.shadow}>
      <form className={classes.form}>
        <div className={classes.formItem} data-type="user-knowledge">
          <div className={classes.fieldsRow}>
            <FormColumn<ICompetenceCategory>
              title={"Добавить область знания"}
              data={selectedCategorys}
              selected={selectedCategorys}
              dataAutocomplite={competenceCategorysList}
              onDeleteField={(value) => {
                updateSelected(
                  {
                    id: value.id,
                    category: value,
                    options: [],
                  },
                  { delete: true },
                );
              }}
              onClickItem={(value) => {
                setActiveCompetenceCategory(value);
              }}
              activeItem={activeCompetenceCategory}
              sortListOff={true}
              autocomplite={true}
              onAutocompliteEvents={(type, value) => {
                if (type === "add" && value) {
                  updateSelected({
                    id: value.data!.id,
                    category: value.data!,
                    options: [],
                  });
                } else if (type === "sub" && value) {
                  updateSelected(
                    {
                      id: value.data!.id,
                      category: value.data!,
                      options: [],
                    },
                    { delete: true },
                  );
                }
              }}
            />
            <FormColumn<ICompetenceCategory>
              title={"Добавить навык"}
              data={selectedOptions}
              selected={selectedOptions}
              dataAutocomplite={competencesList}
              onDeleteField={(value) => {
                if (selectedActive) {
                  updateSelected({
                    id: selectedActive.id,
                    category: selectedActive.category,
                    options: selectedActive.options.filter(
                      (item) => item.id !== value.id,
                    ),
                  });
                }
              }}
              onClickItem={() => void 0}
              sortListOff={true}
              autocomplite={activeCompetenceCategory !== null}
              onAutocompliteEvents={(type, value) => {
                if (selectedActive) {
                  const options = { ...selectedActive };
                  if (type === "add" && value && value.data) {
                    options.options.push(value.data);
                  } else if (type === "sub" && value && value.data) {
                    const items = options.options.filter(
                      (item) => item.id !== value.data?.id,
                    );
                    options.options = items;
                  }

                  updateSelected({
                    id: selectedActive.id,
                    category: selectedActive.category,
                    options: options.options,
                  });
                }
              }}
              disabled={activeCompetenceCategory === null}
            />
          </div>
        </div>
      </form>
      <div className={classes.actions}>
        <Button type="button" clazz={classes.button} theme="fill">
          Создать
        </Button>
      </div>
    </div>
  );
};

export default FormUserKnowledge;
