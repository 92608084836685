import SearchIcon from "components/ui/icons/SearchIcon";
import classes from "./SearchUsers.module.scss";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import { SELECT_OPTIONS } from "forms/components/simpleWidgets/select-input/SelectIntputStyles";
import UserListIcon from "components/ui/icons/UserListIcon";
import { useState } from "react";
import Paper from "components/ui/paper/Paper";

const USER_POST = [
  { name: "Все должности", label: "all", value: "all" },
  {
    name: "Топ менеджер",
    label: "top_manager",
    value: "top_manager",
  },
  {
    name: "Операционный менеджер",
    label: "op_manager",
    value: "op_manager",
  },
  {
    name: "Проектный менеджер",
    label: "project_manager",
    value: "project_manager",
  },
  { name: "Разработчик", label: "developer", value: "developer" },
];

export const SearchUsers = ({
  selectedUsers,
  onChangeSelectedUsers,
  className = "",
  role,
  onChangeRole,
}: {
  selectedUsers: string[];
  onChangeSelectedUsers: (value: string | number | Date | string[]) => void;
  className?: string;
  role: string | null;
  onChangeRole: (value: string | null) => void;
}) => {
  const [openRoleList, setOpenRoleList] = useState(false);
  const q = role ? "?role=" + role : "";
  return (
    <div className={classes.header + " " + className}>
      <SelectWrapper
        name="competences"
        placeholder="Найти сотрудника"
        value={selectedUsers}
        choices_url={"api/employee/employees/choices/" + q}
        onChange={onChangeSelectedUsers}
        searchable={true}
        optionStyle={SELECT_OPTIONS}
        isClearable={false}
        theme="outline"
        className={classes["select-wrapper"]}
        multiple={true}
        startAdornment={<SearchIcon clazz={classes.searchIcon} />}
      />
      <span role="button" onClick={() => setOpenRoleList(!openRoleList)}>
        <UserListIcon clazz={openRoleList ? classes.open : ""} />
      </span>

      {openRoleList && (
        <>
          <div
            className={classes.autocompliteOverlay}
            onClick={() => setOpenRoleList(false)}
          ></div>
          <Paper
            cls={{
              root: classes.autocomplite + " ",
              paddingRoot: classes.autocomplitePadding,
            }}
          >
            <ul className={`${classes.autocompliteList}`} data-element="list">
              {USER_POST.map((item, index) => {
                const isActive = item.value === role;
                return (
                  <li
                    className={`${classes.autocompliteListItem} ${
                      isActive ? classes.active : ""
                    }`}
                    key={index}
                    data-element="list-item"
                    onClick={() => {
                      setOpenRoleList(false);
                      onChangeRole(item.value === "all" ? null : item.value);
                    }}
                  >
                    <span className={classes.autocompliteListItemName}>
                      {item.name}
                    </span>
                  </li>
                );
              })}
            </ul>
          </Paper>
        </>
      )}
    </div>
  );
};
