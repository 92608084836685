import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import classes from "./Skills.module.scss";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import { ApiDataType, SkillTabType } from "types";
import { UserInfoContext } from "context/UserInfoContext";
import Arrow from "components/ui/icons/Arrow";
import SkillsTable from "components/tables/SkillsTable/SkillsTable";
import { useContext, useEffect, useState } from "react";

interface TableParamsType {
  employee?: number;
  category?: number;
}

const Skills = () => {
  const { userInfoContext } = useContext(UserInfoContext);

  const [activeTab, setActiveTab] = useState(0);
  const [skillsList, setSkillsList] = useState<ApiDataType<
    SkillTabType[],
    string[]
  > | null>(null);
  const [tableParams, setTableParams] = useState<TableParamsType>();

  const { apiGet } = useApi();

  useEffect(() => {
    const getSkillsList = async () => {
      const res = await apiGet(endpoints.skillsList);
      setSkillsList({ data: res.data.data, errorMessage: [] });
    };

    getSkillsList();
  }, []);

  useEffect(() => {
    if (userInfoContext) {
      if (skillsList?.data[0].id) {
        setTableParams({
          employee: userInfoContext.data.id,
          category: skillsList?.data[0].id,
        });
      }
    }
  }, [userInfoContext, skillsList]);

  return (
    <>
      <div className={classes.container}>
        {skillsList ? (
          <>
            <div className={classes.header}>Область знаний</div>
            <div className={classes.tabs}>
              <Swiper
                className="skills-swiper"
                watchOverflow={true}
                modules={[Navigation]}
                navigation={{
                  prevEl: ".prev-skill",
                  nextEl: ".next-skill",
                }}
                slidesPerView={"auto"}
                spaceBetween={30}
                slidesOffsetBefore={15}
                slidesOffsetAfter={15}
              >
                {skillsList.data.map((item, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      className={`swiper-slide ${
                        activeTab === index ? "activeTab" : ""
                      }`}
                      onClick={() => {
                        setActiveTab(index);
                        setTableParams({
                          ...tableParams,
                          category: item.id,
                        });
                      }}
                    >
                      {item.name}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className={classes.navigationContainer}>
                <button type="button" className="prev-skill">
                  <Arrow clazz={classes.prev} />
                </button>
                <button type="button" className="next-skill">
                  <Arrow clazz={classes.next} />
                </button>
              </div>
            </div>
          </>
        ) : (
          <Skeleton
            containerClassName={classes["skeleton-container"]}
            style={{
              display: "block",
              width: "100%",
              height: "125px",
            }}
          />
        )}
        {tableParams ? (
          <div className={classes.table}>
            <SkillsTable
              apiUrl={endpoints.currentUserCompetences}
              params={tableParams as URLSearchParams}
            />
          </div>
        ) : (
          <Skeleton
            containerClassName={classes.skeletonTable}
            count={6}
            style={{
              display: "block",
              width: "100%",
              height: "20px",
            }}
          />
        )}
      </div>
    </>
  );
};

export default Skills;
