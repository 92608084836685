import {
  TableResponse,
  TProject,
  TTableColumns,
} from "components/tables/components/dto";
import { useApi } from "hooks/useApi/useApi";
import { PatchDataType, useApiTableType } from "types";
import { newDateFormat, dateToRFC } from "helpers/dates";
import useEffectOnlyOnUpdate from "hooks/useEffectOnlyOnUpdate/useEffectOnlyOnUpdate";
import { useCallback, useEffect, useState } from "react";

const useApiTable = (
  url: string,
  params?: URLSearchParams,
  testData?: () => TableResponse,
  updateTable?: boolean,
  setUpdateTable?: React.Dispatch<React.SetStateAction<boolean>>,
): useApiTableType => {
  const { apiGet, apiPatch, apiDelete } = useApi();
  const [columns, setColumns] = useState<TTableColumns[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<TProject[]>([]);
  const [pages, setPages] = useState({
    current_page_number: 1,
    items_per_page: 1,
    total_pages: 1,
    total_items: 1,
  });

  const fetchTable = useCallback(async () => {
    setIsLoading(true);

    if (testData) {
      setColumns(testData().columns);
      setData(testData().data);
    } else {
      try {
        let res;

        if (pages.current_page_number > 1) {
          res = await apiGet(
            `${url}&page=${pages.current_page_number}`,
            params,
          );
        } else {
          res = await apiGet(url, params);
        }

        setColumns(res.data.columns);
        setData(res.data.data);
        setPages({
          current_page_number: res.data.current_page_number,
          items_per_page: res.data.items_per_page,
          total_pages: res.data.total_pages,
          total_items: res.data.total_items,
        });
      } catch (e) {
        console.error(e);
      }
    }
    setIsLoading(false);
    if (updateTable && setUpdateTable) setUpdateTable(false);
  }, [testData, params, updateTable, pages.current_page_number, url]);

  const sortTable = useCallback(
    async (column: string) => {
      setIsLoading(true);

      let newUrl = `${url}`;

      url.includes("?")
        ? (newUrl += `&ordering=${column}`)
        : (newUrl += `?ordering=${column}`);

      const res = await apiGet(newUrl, params);

      setData(res.data.data);

      setIsLoading(false);
    },
    [url],
  );

  useEffect(() => {
    fetchTable();
  }, [params, pages.current_page_number, url]);

  useEffectOnlyOnUpdate(() => {
    if (updateTable) fetchTable();
  }, [updateTable]);

  const updateData = useCallback(
    async (
      taskId: number,
      patchValues: PatchDataType | null,
      cell?: number,
      value?: string | number | Date,
    ) => {
      let patchData: PatchDataType = {};

      if (patchValues) {
        patchData = patchValues;
      } else {
        switch (cell) {
          case 1:
            typeof value === "string" && (patchData.task = value);
            break;
          case 2:
            typeof value === "number" && (patchData.project = value);
            break;
          case 3:
            typeof value === "string" &&
              (patchData.date = dateToRFC(newDateFormat(value)));
            break;
          case 4:
            typeof value === "string" && (patchData.action_type = value);
            break;
          case 5:
            typeof value === "string" && (patchData.spent_time_min = value);
            break;
        }
      }

      return await apiPatch(
        `${process.env.REACT_APP_API_URL}api/project/project_time_tracking/${taskId}/`,
        patchData,
        params,
      );
    },
    [],
  );

  const deleteData = useCallback(async (taskId: number) => {
    return await apiDelete(
      `${process.env.REACT_APP_API_URL}api/project/project_time_tracking/${taskId}/`,
      params,
    );
  }, []);

  return {
    columns,
    data,
    pages,
    isLoading,
    sortTable,
    updateData,
    deleteData,
    setPages,
  };
};

export default useApiTable;
