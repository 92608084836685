import {
  ControlProps,
  CSSObjectWithLabel,
  GroupBase,
  OptionProps,
  StylesConfig,
} from "react-select";
import { OptionType } from "./SelectWrapper";

export type TSelctInputTheme = "shadow" | "outline";

export const SELECT_OPTIONS: React.CSSProperties & CSSObjectWithLabel = {
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "20px",
  textAlign: "left",
  padding: "10px 10px",
  position: "relative",
};

export const selectInputStyles = <IsMulti extends boolean>({
  error,
  theme = "shadow",
  optionStyle = {},
  multiple,
}: {
  multiple?: boolean;
  error?: string;
  theme?: TSelctInputTheme;
  optionStyle?: React.CSSProperties;
}): StylesConfig<OptionType, IsMulti, GroupBase<OptionType>> => {
  const isShadow = theme === "shadow";
  return {
    input: (styles: CSSObjectWithLabel) => ({
      ...styles,
      paddingTop: 0,
      height: "fit-content",
      marginTop: "-2px",
      color: "#999",
      fontWeight: 500,
      fontSize: 16,
    }),
    dropdownIndicator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      width: "30px",
      height: "100%",
      position: "absolute",
      top: isShadow ? "-6px" : "0px",
      right: "-27px",
    }),
    singleValue: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: "#999",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "16px",
      margin: 0,
    }),

    menu: (styles: CSSObjectWithLabel) => ({
      ...styles,
      width: "max-content",
      minWidth: "100%",
      padding: "0px 6px",
    }),
    menuList: (styles: CSSObjectWithLabel) => {
      return {
        ...styles,
        scrollbarWidth: "auto",
        scrollbarColor: "initial",
        "&>div:nth-child(1)": {
          borderBottom: "1px solid #F2F2F2",
        },
        "&::-webkit-scrollbar": {
          width: "7px",
          height: "7px",
        },

        "&::-webkit-scrollbar-track": {
          background: "#F2F2F2",
          width: "2px",
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E2E2E2",
          borderRadius: "30px",
          borderColor: "#E2E2E2",
        },
      };
    },
    option: (
      styles: CSSObjectWithLabel,
      { isDisabled, isSelected }: OptionProps<OptionType>,
    ) => {
      return {
        ...styles,
        color: isSelected ? "#00C2FA" : isShadow ? "#b6b6b6" : "#181818",
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: "16px",
        background: isDisabled ? "#f2f2f2" : "none",
        textAlign: "center",
        cursor: isDisabled ? undefined : "pointer",
        transition: "color 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        "&:hover": {
          background: isDisabled ? undefined : "none",
          color: isSelected
            ? "#00C2FA"
            : isDisabled
            ? undefined
            : isShadow
            ? "#00c2fa"
            : "#181818",
        },
        ...optionStyle,
      };
    },
    control: (
      styles: CSSObjectWithLabel,
      { isDisabled, isFocused }: ControlProps<OptionType>,
    ) => {
      return {
        ...styles,
        boxShadow: isShadow
          ? "0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 4px rgba(0, 0, 0, 0.13)"
          : undefined,
        minHeight: isShadow ? "24px" : "40px",
        cursor: "pointer",
        borderRadius: isShadow ? "4px" : "8px",
        border: isShadow ? "none" : "2px solid #f2f2f2",
        outline: isDisabled
          ? "none"
          : isFocused
          ? "1px solid #00c2fa"
          : error
          ? "1px solid #ff7676"
          : "none",
        ":hover": isShadow
          ? { ...styles[":hover"], border: "none" }
          : { ...styles[":hover"], borderColor: "#00c2fa" },
      };
    },
    valueContainer: (styles: CSSObjectWithLabel) => ({
      ...styles,
      padding: isShadow ? "4px 25px" : "4px 16px 4px 16px",
      justifyContent: isShadow ? "center" : "flex-start",
      height: "fit-content",
      color: "#999",
      fontWeight: 500,
      fontSize: 16,
      flexWrap: "nowrap",
      overflow: "unset",
      flexDirection: multiple ? "row-reverse" : "row",
    }),
    indicatorSeparator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      display: "none",
    }),
    indicatorsContainer: (styles: CSSObjectWithLabel) => ({
      ...styles,
      padding: 0,
      position: "absolute",
      right: "25px",
    }),
    clearIndicator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      position: "absolute",
      right: "-10px",
      top: "-5px",
      cursor: "pointer",
      display: multiple ? "none" : "flex",
      ":hover": {
        color: "#00c2fa",
      },
    }),
    container: (styles: CSSObjectWithLabel) => ({
      ...styles,
      width: "100%",
    }),
    placeholder: (styles: CSSObjectWithLabel) => ({
      ...styles,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingRight: "5px",
    }),
    multiValue: (styles: CSSObjectWithLabel) => ({
      ...styles,
      backgroundColor: "#00c2fa",
      borderRadius: "4px",
      display: "flex",
    }),
    multiValueLabel: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: "#fff",
    }),
    multiValueRemove: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: "#fff",
      path: {
        color: "#fff",
      },
      "&:hover": {
        backgroundColor: "#00c2fa",
      },
    }),
  };
};
