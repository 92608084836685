import { useContext, useEffect, useState } from "react";
import classes from "./ManagementPage.module.scss";
import ManagementHeader from "components/management/ManagementHeader/ManagementHeader";
import ManagementSkills from "components/management/ManagementSkills/ManagementSkills";
import { UserRoleContext } from "context/RoleContext";
import { useNavigate } from "react-router-dom";
import { AnalyticsDashboard } from "components/analytics-hr";
type DashboardType = "skills" | "analytics" | "archive";

const ManagementPage = () => {
  const [tab, setTab] = useState("skills");
  const { userRoleContext } = useContext(UserRoleContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "ERP - Management";
    if (!userRoleContext.data?.can_create_project) {
      navigate("/");
    }
  }, []);

  const dashboard = {
    skills: <ManagementSkills />,
    analytics: <AnalyticsDashboard />,
    archive: <div />,
  };

  return (
    <section className={classes.mainContainer}>
      <ManagementHeader setTab={setTab} />
      {dashboard[tab as DashboardType]}
    </section>
  );
};

export default ManagementPage;
