import classes from "./UserCart.module.scss";
import EmptyImage from "assets/img/empty-image.png";

const UserCart = ({
  avatar,
  name,
  position,
  small = false,
}: {
  avatar?: string | null;
  name: string;
  position: string;
  small?: boolean;
}) => {
  return (
    <div className={classes.card + " " + (small ? classes.cardSmall : "")}>
      <img src={avatar || EmptyImage} alt="" className={classes.avatar} />
      <div className={classes.info}>
        <h4 className={classes.infoName}>{name}</h4>
        <p className={classes.infoPosition}>{position}</p>
      </div>
    </div>
  );
};

export default UserCart;
